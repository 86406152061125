import * as React from 'react';
import { graphql } from 'gatsby';
import Article from '../components/Article';
import Layout from '../components/Layout';

const BlogPost = ({ data }) => {
  const article = data.article;

  return (
    <Layout>
      <Article {...article} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    article: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        author
        authorImage {
          childImageSharp {
            fluid(quality: 85, maxWidth: 960) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        description
        image {
          childImageSharp {
            fluid(quality: 85, maxWidth: 960) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default BlogPost;
