import * as React from 'react';
import { withBreakpoints } from 'react-breakpoints';
import { Grid, Cell } from 'styled-css-grid';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import CTA from '../CTA';
import * as S from './styles';
import { useMobileBreakpoint } from '../../utils/breakpoints';

export interface IProps {
  breakpoints: any;
  currentBreakpoint: string;
  html?: any;
  frontmatter?: {
    title?: string;
    author?: string;
    authorImage?: any;
    date?: string;
    description: string;
    image?: any;
  };
}

const Meta = props => (
  <S.Meta>
    <S.Description hasLine={true}>{props.frontmatter.description}</S.Description>
    <S.Title>
      <h1>{props.frontmatter.title}</h1>
    </S.Title>
    <S.AuthorDetails>
      {props.frontmatter.authorImage && (
        <S.AuthorImage>
          <Img
            alt={props.frontmatter.author}
            fluid={
              props.frontmatter &&
              props.frontmatter.authorImage &&
              props.frontmatter.authorImage.childImageSharp.fluid
            }
          />
        </S.AuthorImage>
      )}
      <S.AuthorNameDate>
        {props.frontmatter.author && (
          <S.AuthorName>{props.frontmatter.author}</S.AuthorName>
        )}
        <S.Date>{props.frontmatter.date}</S.Date>
      </S.AuthorNameDate>
    </S.AuthorDetails>
  </S.Meta>
);

const Article = (props: IProps) => {
  const { breakpoints, currentBreakpoint } = props;
  const isMobile = useMobileBreakpoint(breakpoints, currentBreakpoint);
  const href = typeof window !== `undefined` && window.location.href;

  return (
    <S.Article>
      <article>
        <header>
          {isMobile ? (
            <Grid columns={24}>
              <Cell width={24} left={1}>
                <Img
                  fluid={
                    props.frontmatter &&
                    props.frontmatter.image &&
                    props.frontmatter.image.childImageSharp.fluid
                  }
                />
              </Cell>
              <Cell width={22} left={2}>
                <Meta {...props} />
              </Cell>
            </Grid>
          ) : (
            <Grid columns={24}>
              <Cell width={18} left={4}>
                <BackgroundImage
                  Tag="div"
                  fluid={
                    props.frontmatter &&
                    props.frontmatter.image &&
                    props.frontmatter.image.childImageSharp.fluid
                  }
                  className="backgroundImage"
                  backgroundColor="var(--grey)"
                  classId=""
                >
                  <S.Box>
                    <Meta {...props} />
                  </S.Box>
                </BackgroundImage>
              </Cell>
            </Grid>
          )}
        </header>
        <Grid columns={24}>
          <Cell width={isMobile ? 22 : 10} left={isMobile ? 2 : 6}>
            <S.Content>
              <p dangerouslySetInnerHTML={{ __html: props.html }} />
            </S.Content>
          </Cell>
          <Cell width={isMobile ? 22 : 12} left={isMobile ? 2 : 6}>
            <S.Footer>
              <footer>
                <S.Button className="facebook">
                  <CTA
                    type="button"
                    to={`https://www.facebook.com/sharer/sharer.php?u=${href}`}
                    onClick={e => {
                      window.open(
                        `https://www.facebook.com/sharer/sharer.php?u=${href}`,
                        'facebook-share-dialog',
                        'width=626,height=436'
                      );
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    target="_blank"
                    title="Share"
                  >
                    Share
                  </CTA>
                </S.Button>
                <S.Button className="twitter">
                  <CTA
                    type="button"
                    to="https://twitter.com/intent/tweet?text=Hey, I’ve just read this interesting article from Zero-1"
                    target="_blank"
                    title="Tweet"
                  >
                    Tweet
                  </CTA>
                </S.Button>
              </footer>
            </S.Footer>
          </Cell>
        </Grid>
      </article>
    </S.Article>
  );
};

export default withBreakpoints(Article);
