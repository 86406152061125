import styled from 'styled-components'
import { Section }  from '../Layout/styles'
import * as T from '../Typography'
import Quote from '../../images/bgQuote.svg'
import Facebook from '../../images/bgFacebook.svg'
import Twitter from '../../images/bgTweet.svg'

export const Article = styled(Section)``

export const Box = styled.div`
  min-height: 60vh;
  position: relative;
`

export const Meta = styled.div`
  margin-top: var(--x2-space);

  @media (min-width: ${props => props.theme.tabletBreak}) {
    margin: var(--x4-space) var(--x6-space);
    position: absolute;
    left: 0;
    bottom: 0;
  }
`

export const Description = styled(T.Label)``

export const Title = styled(T.H3)`
  margin-top: var(--x1-space);
`

export const AuthorDetails = styled.div`
  margin-top: var(--x1-space);
`

export const AuthorImage = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 48px;
  height: 48px;
`

export const AuthorNameDate = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin: calc(var(--xhalf-space) * -1) 0 0 var(--x1-space);
`

export const AuthorName = styled(T.SmallBodyCopy)`
  color: var(--white);
`

export const Date = styled(T.SmallBodyCopy)`
  color: var(--grey);
  line-height: .875rem;
`

export const Content = styled(T.SmallBodyCopy)`
  margin-top: var(--x4-space);

  /* stylelint-disable */
  & p {
    margin-top: var(--x2-space);
  }

  & .gatsby-resp-image-wrapper {
    max-width: none !important;
  }

  & blockquote {
    font: 400 var(--type-4) var(--font-mark-pro-medium);
    padding-left: var(--x3-space);
    background-image: url(${Quote});
    background-repeat: no-repeat;
    background-size: 38px 29px;
    margin: var(--x1-space) 0 var(--x4-space);

    @media (min-width: ${props => props.theme.tabletBreak}) {
      margin-left: var(--x4-space);
    }
  }
  /* stylelint-enable */
`

export const Footer = styled.div`
  margin-top: var(--x4-space);
`

export const Button = styled.div`
  display: inline-block;
  background-repeat: no-repeat;
  background-position: var(--x1-space) center;

  &:not(:first-child) {
    margin-left: var(--x1-space);
  }

  &.facebook {
    background-image: url(${Facebook});
    background-size: 8px 16px;
  }

  &.twitter {
    background-image: url(${Twitter});
    background-size: 15px 12px;
  }

  & .button {
    padding-left: var(--x3-space);
  }
`
